body {
    background-color: rgb(30,30,30); /*#282c34;*/
}

.hLayoutRow {
  min-height: 110mm;
  max-height: 110mm;
  background-color: rgb(30,30,30);
}

.appPhoneScreen {
  margin:0px;
  padding: 0px;
  display: block;
  float:left;
  min-height:60mm;
  max-width: 60mm;
  min-height: 110mm;
  max-height: 110mm;
  background-color: grey;
}

.App {
  text-align: center;
    background-color: #282c34;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  /*background-color:grey;*/
  min-height: 8vh;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.jsonViewer {
  text-align: left;
  font-size:12px;
}

.showconfig {
  align:left;

}
