.vignetteChant {
  display:block;
  border-radius: 10px;
  padding: 5px;
  margin: 3px;
  background-color: white;
  width:50mm;
  height:15mm;
  font-size:10px;
  text-align:left;
}

.left-zone {
    display:block;
    float:left;
    width:10mm;
    height:15mm;
    /* background-color:blue; */
}

.center-zone {
    display:block;
    float:left;
    width:30mm;
    height:15mm;
    /* background-color:orange; */
}

.right-zone {
    display:block;
    float:right;
    width:10mm;
    height:15mm;
    /* background-color:grey; */
}

.titre {
  display:inline-block;
  height:8mm;
  width:30mm;
  /* background-color:lightblue; */
}

.dessous_centre {
    display:block;
    width:30mm;
    height:7mm;
    /* background-color:violet; */
}

.cote {
  display:inline-block;
  width:12mm;
  margin-top: 1mm;
  /* background-color: yellow; */
}

.authComp {
  display:inline-block;
  vertical-align: top;
  float:right;
  padding:0px;
  margin:0px;
  display:inline-block;
  width:18mm;
  height:6mm;
  white-space:nowrap;
  text-overflow: ellipsis;
  /* background-color: green; */
}

.auteur {
  display:block;
  width:18mm;
  height:3.5mm;
  max-height: 4mm;
  max-width:18mm;
  font-size:8px;
  white-space:nowrap;
  text-overflow:ellipsis;
  /* background-color: red; */
}

img {
  height:3mm;
}

.compo {
  display:block;
  margin: 0px;
  padding: 0px;
  width:18mm;
  height:3.5mm;
  max-height: 3.5mm;
  max-width:18mm;
  white-space:nowrap;
  text-overflow:ellipsis;
  font-size:8px;
  /* background-color: pink; */
}

img {
  position:relative;
  top:1mm;
}
