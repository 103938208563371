.items_container {
  display: block;
  /*background-color:rgb(0,100,240);*/
  background-color: #282c34;
  /*background-color: red;*/
  height:75mm;
  width:57mm;
  overflow-y: auto;
  overflow-x: hidden;
}
