.viewResults1 {
  display:block;
  float:left;
  vertical-align:top;
  background-color: rgb(160,80,80);
  /*width:250px;*/
  /*height:500px;*/
  min-height:60mm;
  max-width: 60mm;
  min-height: 110mm;
  max-height: 110mm;
  text-align:left;
  overflow: auto;
}
