#btnSearch {
  vertical-align: middle;
  margin-left: 3px;
  width:10px;
}

#btnSearchImg {
  width:20px;
  height:20px;
  left:-10px;
  top:2px;
}

.textInput {
  width:150px;
}
