/*.exampleContainer {
  background-color: rgb(60, 60, 60);
  padding:10px;
}*/

.switch {
  position: relative;
  display: inline-block;
  width: 9mm;
  height: 9mm;
  margin:0.5mm;
}

.rectButton {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(120, 120, 120);
}

.rectButton img {
  width:8mm;
  height:8mm;
}
