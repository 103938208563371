/*.exampleContainer {
  background-color: rgb(60, 60, 60);
  padding:10px;
}*/

.switch {
  position: relative;
  display: inline-block;
  width: 10mm;
  height: 10mm;
}

.btnOnOff.rectButton {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(120, 120, 120);
}

.btnOnOff.rectButton img {
  width:8mm;
  height:8mm;
}

.btnOnOff.checkedClass {
  box-shadow: 0 0 1px rgb(240, 240, 145);
}

.btnOnOff.checkedClass {
  background-color: rgb(240, 240, 130);
}
