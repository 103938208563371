.pager_ctrl {
  display:block;
  /*background-color: green;*/
  /*background-color: #281c54;*/
  
  height:36px;
  /*color:rgb(220,95,95);*/
  color:rgb(120, 120, 120);
  font-size: 10px;
}

.pager_ctrl button  {
  border:0px;
  margin:4px 0px 4px 3px;
  padding-left: 5px;
  padding-right: 5px;
  height:7mm;
  width:7mm;
  /*background-color: rgb(220,95,95);*/
  background-color: rgb(120, 120, 120);
  color:black;
}
