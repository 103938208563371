.viewListeChants {
  display: block;
  height:340px;

  padding-left: 5px;
  /*background-color: red;*/
  background-color: #282c34;

}

.lbl {
  color:rgb(200,200,200);
}
