.viewJsonRequest {
  display:block;
  vertical-align:top;
  float:left;
  min-height: 200px;
  min-width: 300px;
  width:300px;
  background-color: green;
  text-align:left;
}
